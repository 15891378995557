<template>
  <div>
    <hero />

    <contents />
  </div>
</template>

<script>
  export default {
    name: 'About',
    components: {
      Hero: () => import('@/components/about/Hero'),
      Contents: () => import('@/components/about/Contents')
    }
  }
</script>

<style scoped>

</style>
